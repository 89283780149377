.sticky-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 80px;
  font-size: 16px;
  background-color: #cabe9f;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  z-index: 9999999;
}
.sticky-header span {
  color: #000000c4;
  font-weight: 500;
  border-bottom: 2px solid #f1f1f100;
  cursor: pointer;
}
.sticky-header .span-active {
  color: #000000;
  border-bottom: 2px solid #000000;
}

@media (max-width: 768px) {
  .sticky-header {
    display: none;
  }
}
.sticky-comp {
  position: fixed;
  bottom: 30px;
  left: 20px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 20000000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: slideInUp;
  animation-duration: 1s;
}
