/**,
*:after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}*/

.rpm-container,
.rpm-scroller {
  height: 100%;
  min-height: 640px;
}

// .rpm-scroller {
//     overflow-y: scroll;
// }

.rpm-scroller,
.rpm-scroller-inner {
  position: relative;
}

.rpm-container {
  position: relative;
  // overflow: hidden;
  /*background: #34495e;*/
  @media (max-width: 768px) {
    overflow: hidden;
  }
}

.rpm-menu-trigger {
  position: relative;
  padding-left: 60px;
  font-size: 0.9em;
}

.rpm-menu-trigger:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 40px;
  height: 6px;
  background: #fff;
  box-shadow: 0 6px #34495e, 0 12px #fff, 0 18px #34495e, 0 24px #fff;
  content: "";
}

.rpm-mp-pusher {
  position: relative;
  left: 0;
  height: 100%;
}

.rpm-mp-menu {
  position: absolute; /* we can't use fixed here :( */
  top: 0;
  left: 0;
  z-index: 1000;
  width: 300px;
  height: 100%;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.rpm-mp-menu a {
  text-decoration: none;
  color: #fff;
}

.rpm-mp-level {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #353535;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

/* overlays for pusher and for level that gets covered */
.rpm-mp-pusher::after,
.rpm-mp-level::after,
.rpm-mp-level::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  opacity: 0;
}

.rpm-mp-pusher::after,
.rpm-mp-level::after {
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
}

.rpm-mp-level::after {
  z-index: -1;
}

.rpm-mp-pusher.rpm-mp-pushed::after,
.rpm-mp-level.rpm-mp-level-overlay::after {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.rpm-mp-level.rpm-mp-level-overlay {
  cursor: pointer;
}

.rpm-mp-level.rpm-mp-level-overlay.rpm-mp-level::before {
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 1;
}

.rpm-mp-pusher,
.rpm-mp-level {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

/* overlap */
.rpm-mp-overlap .rpm-mp-level.rpm-mp-level-open {
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(-40px, 0, 0);
  -moz-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0);
}

/* First level */
.rpm-mp-menu > .rpm-mp-level,
.rpm-mp-menu > .rpm-mp-level.rpm-mp-level-open,
.rpm-mp-menu.rpm-mp-overlap > .rpm-mp-level,
.rpm-mp-menu.rpm-mp-overlap > .rpm-mp-level.rpm-mp-level-open {
  box-shadow: none;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* cover */
.rpm-mp-cover .rpm-mp-level.rpm-mp-level-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.rpm-mp-cover
  .rpm-mp-level.rpm-mp-level-open
  > ul
  > li
  > .rpm-mp-level:not(.rpm-mp-level-open) {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

/* content style */
.rpm-mp-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rpm-mp-menu .rpm-mp-header,
.rpm-mp-menu h2 {
  margin: 0;
  //padding: 1em;
  color: rgba(0, 0, 0, 0.4);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  font-weight: 300;
  font-size: 2em;
}

.rpm-inline-block {
  display: inline-block;
}
.rpm-node-link {
  width: calc(100% - 50px);
  cursor: pointer;
}
.rpm-node-exp {
  /*width:10%;*/
  //font-size: 20px;
  font-size: 1em;
  cursor: pointer;
  padding: 0.7em 1em 0.7em 1em;
  color: white;
  width: 50px;
  text-align: center;
}
.rpm-node-exp i {
  /*position: relative;*/
  top: 8px;
}
.rpm-node-exp:hover {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
}

.rpm-mp-menu.rpm-mp-overlap h2::before {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 8px;
  font-size: 75%;
  line-height: 1.8;
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.1s 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.1s 0.3s;
  transition: opacity 0.3s, transform 0.1s 0.3s;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.rpm-mp-menu.rpm-mp-cover .rpm-mp-header .rpm-mp-menu.rpm-mp-cover h2 {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 1em;
}

.rpm-mp-overlap .rpm-mp-level.rpm-mp-level-overlay > h2::before {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.rpm-mp-menu ul li > .rpm-node-cntr {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.15);
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
}

.rpm-mp-menu ul li > .rpm-node-cntr > a {
  /*display: block;*/
  padding: 0.7em 3em 0.7em 1.2em;
  outline: none;
  /*box-shadow: inset 0 -1px rgba(0,0,0,0.2);*/
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  font-size: 1em;
  -webkit-transition: background 0.3s, box-shadow 0.3s;
  -moz-transition: background 0.3s, box-shadow 0.3s;
  transition: background 0.3s, box-shadow 0.3s;
}

.rpm-mp-menu ul li::before {
  position: absolute;
  left: 10px;
  z-index: -1;
  color: rgba(0, 0, 0, 0.2);
  line-height: 3.5;
}

.rpm-mp-level > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2), inset 0 1px rgba(0, 0, 0, 0.2);
}

.rpm-mp-menu ul li a:hover,
.rpm-mp-level > ul > li:first-child > a:hover {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
}

.rpm-mp-menu .rpm-mp-level.rpm-mp-level-overlay > ul > li > a,
.rpm-mp-level.rpm-mp-level-overlay > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
}

.rpm-mp-level > ul > li:first-child > a:hover,
.rpm-mp-level.rpm-mp-level-overlay > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0), inset 0 1px rgba(0, 0, 0, 0);
} /* seems like Chrome 34.0.1847.131 needs the second shadow otherwise the transition breaks */

.rpm-back-item {
  padding: 0 10px;
}
span.rpm-back-item svg {
  font-size: 10px;
}

.rpm-mp-back > a {
  padding: 1em;
}

.rpm-mp-back > a,
.rpm-mp-back {
  background: rgba(0, 0, 0, 0.1);
  outline: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  display: block;
  font-size: 0.8em;
  position: relative;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  transition: background 0.3s;
}

.rpm-mp-back svg {
  font-size: 20px;
}

.rpm-mp-menu .rpm-mp-level.rpm-mp-level-overlay > .rpm-mp-back,
.rpm-mp-menu .rpm-mp-level.rpm-mp-level-overlay > .rpm-mp-back::after {
  background: transparent;
  box-shadow: none;
  color: transparent;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
/* We'll show the first level only */
.no-csstransforms3d .rpm-mp-pusher,
.no-js .rpm-mp-pusher {
  padding-left: 300px;
}

.no-csstransforms3d .rpm-mp-menu .rpm-mp-level,
.no-js .rpm-mp-menu .rpm-mp-level {
  display: none;
}

.no-csstransforms3d .rpm-mp-menu > .rpm-mp-level,
.no-js .rpm-mp-menu > .rpm-mp-level {
  display: block;
}
