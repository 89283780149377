@import "../../../../public/assets/scss/base/variables";
.contact-page-wrap {
  .box-content {
    margin-bottom: 30px;
    .box-title {
      font-size: 18px;
    }
    a {
      color: $gray5;
      &:hover {
        color: $primary;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .form-contact {
    max-width: 770px;
    margin: 0 auto;
    padding: 0 30px;
    p {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      font-family: $headings-font-family;
    }
    h3 {
      margin-bottom: 70px;
      color: $primary;
      font-size: 30px;
    }
  }
  .box-contact {
    margin-top: 50px;
  }
}
.box-icon {
  text-align: center;
  border-right: 1px solid $border-color;
  @media (max-width: 992px) {
    border-right: 0;
  }
  @media (max-width: 575px) {
    margin-bottom: 30px;
  }
  &.no-border {
    border-right: 0;
  }
  .icon {
    font-size: 65px;
    color: $primary;
    margin-bottom: 25px;
    line-height: 65px;
    @media (max-width: 992px) {
      margin-bottom: 0px;
    }
    .opal-icon-phone {
      -webkit-transform: rotate(265deg);
      transform: rotate(265deg);
    }
  }
  .title {
    font-size: 13px;
    margin-bottom: 20px;
    display: block;
  }
  .description {
    color: $black1;
    font-size: 14px;
  }
}
.icon-list {
  ul {
    list-style: none;
  }
}
.footer-address{
  .icon-list-icon{
      width: 22%;
  }
  .icon-list-text{
      width: 75%;
  }
}